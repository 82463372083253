import { Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import classes from '../../api/classes';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { Banner } from '../../components/Banner';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import { SmartHelp } from '../../components/Smarts/SmartHelp';
import { Toast } from '../../components/Toast/Toast';
import { PageContentWrapper } from '../../components/Wrappers';
import Form from '../../containers/Form';
import { useMotdQuery, useRemoveMotdMutation, useUpdateMotdMutation } from './query';

const { Motd } = classes;

export function AdminNotificationsPage() {
	const { data } = useMotdQuery();
	const { mutate: updateMotd, isError: updateMotdError, isSuccess: updateMotdSuccess } = useUpdateMotdMutation();
	const { mutate: removeMotd, isError: removeMotdError, isSuccess: removeMotdSuccess } = useRemoveMotdMutation();

	return (
		<PageContentWrapper title="Notifications">
			<Toast
				open={updateMotdSuccess}
				status="success"
				message="Message of the Day updated"
				timeout={5}
			/>
			<Toast
				open={updateMotdError}
				status="error"
				message="Failed to update Message of the Day"
			/>
			<Toast
				open={removeMotdSuccess}
				status="success"
				message="Message of the Day removed"
				timeout={5}
			/>
			<Toast
				open={removeMotdError}
				status="error"
				message="Failed to remove Message of the Day"
			/>
			<MessageOfTheDayForm model={data} onSubmit={() => updateMotd(data)} onRemove={removeMotd} />
		</PageContentWrapper>
	);
}

export const MessageOfTheDayForm = Form(({
	model,
	field,
	submit,
	onRemove,
}: FormProps & { model: typeof Motd, onRemove: (model: typeof Motd) => void }) => {
	const { isLoading } = useMotdQuery();
	const isActive = !!model?.message;

	return (
		<Grid item xs={12}>
			<Card>
				<CardContent>
					<div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
						<Typography variant="h2">
							Message of the Day
						</Typography>
						<SmartHelp
							query="Message of the Day"
							question="What is Message of the Day feature and how do you set it up?"
						/>
					</div>

					<Grid container spacing={2}>
						<Grid item>
							<Typography variant="h3">
								<b>Status:</b> {isActive ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Select
								label="Style"
								items={[
									{ label: 'Info', value: 'info' },
									{ label: 'Warning', value: 'warning' },
									{ label: 'Caution', value: 'error' },
								]}
								disabled={isLoading}
								// fullWidth
								{...field('status')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField label="Title (optional)" fullWidth disabled={isLoading} {...field('title')} />
						</Grid>
						<Grid item xs={12}>
							<TextField label="Message" required fullWidth disabled={isLoading} {...field('message')} />
						</Grid>
						<Grid item xs={12}>
							<Checkbox label="Closable" disabled={isLoading} {...field('closable')} />
							<Checkbox label="Show for Admins" disabled={isLoading} {...field('showForAdmins')} />
							<Checkbox label="Show for Publisher Users" disabled={isLoading} {...field('showForUsers')} />
						</Grid>
					</Grid>

					<div style={{ marginTop: '20px' }}>
						<Typography variant="h3" sx={{ marginBottom: '10px' }}>Example</Typography>
						<Banner
							{...model}
							onClose={model?.closable ? () => { } : undefined}
						/>
					</div>
				</CardContent>

				<CardActions className="m-2">
					<ActionButton
						label="Save"
						color="primary"
						onClick={submit}
					/>
					<ActionButton
						label="Remove"
						variant="outlined"
						onClick={() => onRemove(model)}
						disabled={!isActive}
					/>
				</CardActions>
			</Card>
		</Grid>
	);
});
