import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import styles from './styles.css';
import Dashboard from '../../containers/Dashboard';
import { MOTD } from '../../containers/Dashboard/MOTD';

function ProgrammaticPublisherDashboard() {
	return (
		<div className={styles.pageContentWrapper}>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<MOTD />
					<Dashboard type="programmatic" />
				</Grid>
			</Container>
		</div>
	);
}

ProgrammaticPublisherDashboard.propTypes = {
};

ProgrammaticPublisherDashboard.defaultProps = {

};

export default ProgrammaticPublisherDashboard;
