const { renameToInternalParams } = require('./constants');
const { NATIVE_FIELDS, NATIVE_IMAGE_FIELDS } = require('./native');
const EnvReportInterface = require('../reportData/envReportInterface');
const BuiltInPlacementFields = require('./builtInPlacementTagFields');
const BuiltInPbConfigTagFields = require('./builtInPbConfigTagFields');
const BuiltInSiteTagFields = require('./builtInSiteTagFields');
const PubxSiteTagFields = require('./pubxSiteTagFields');

const splitToDesc = (str) => {
	let res = str;
	for (let i = res.length - 1; i >= 0; i -= 1) {
		const c = res.charCodeAt(i);
		if ((c >= 65 && c <= 90) || (c >= 48 && c <= 57)) {
			res = `${res.slice(0, i)} ${res.slice(i)}`;
		}
	}
	return res[0].toUpperCase() + res.slice(1);
};

const sharedVideoFields = [
	{
		type: 'Object',
		name: 'playerSize',
		description: 'Player size',
		fields: [
			{
				type: 'Number',
				name: 'width',
				description: 'Width',
				defaultValue: 1,
				isRequired: true,
			},
			{
				type: 'Number',
				name: 'height',
				description: 'Height',
				defaultValue: 1,
				isRequired: true,
			},
		],
	},
	{
		type: 'Number',
		name: 'minduration',
		description: 'Min duration',
		defaultValue: 3,
	},
	{
		type: 'Boolean',
		name: 'skip',
		description: 'Skippable',
		defaultValue: false,
	},
	{
		type: 'String',
		name: 'playbackmethod',
		description: 'Playback method',
		hasOptions: true,
		isRequired: true,
		defaultValue: '2',
		options: [
			{
				name: '1',
				label: 'Initiate on page load with sound on',
			},
			{
				name: '2',
				label: 'Initiate on page load with sound off by default',
			},
			{
				name: '3',
				label: 'Initiate on click with sound on',
			},
			{
				name: '4',
				label: 'Initiate on mouse over with sound on',
			},
			{
				name: '5',
				label: 'Initiate on entering viewport with sound on',
			},
			{
				name: '6',
				label: 'Initiate on entering viewport with sound off by default',
			},
		],
	},
];

const PrebidDataObjects = {
	generalSettings: {
		name: 'General settings',
		availForNode(node) {
			return !(node && node.isBidderNode);
		},
		getTagFields() {
			return [
				{
					type: 'Boolean',
					name: 'mobileEnabled',
					description: 'Enable Mobile',
					defaultValue: false,
				},
				{
					type: 'Boolean',
					name: 'ampEnabled',
					description: 'Enable AMP',
					defaultValue: false,
				},
			];
		},
		excludeCustomParameters: true,
	},
	genericPlacementData: {
		name: 'Generic Placement Data',
		availForNode(node) {
			return !(node && node.isBidderNode);
		},
		getTagFields({ globalSettings }) {
			return [
				...globalSettings.placementTag.fields,
				...BuiltInPlacementFields,
			];
		},
	},
	genericPbConfigData: {
		name: 'Generic Prebid Config Data',
		availForNode(node, pbConfig, dstObj) {
			return !pbConfig
				&& !node?.getParentOfType('PlacementNode', true)
				&& dstObj?.constructor?.clsName?.() !== 'PlacementType';
		},
		getTagFields({ globalSettings }) {
			return [
				...globalSettings.pbConfigTag.fields,
				...BuiltInPbConfigTagFields,
			];
		},
	},
	genericSiteData: {
		name: 'Generic Site Data',
		availForNode(...args) {
			return PrebidDataObjects.genericPbConfigData.availForNode(...args);
		},
		getTagFields({ globalSettings }) {
			return [
				...globalSettings.siteTag.fields,
				...BuiltInSiteTagFields,
				...(EnvReportInterface.getGenericData().pubxEnabled ? PubxSiteTagFields : []),
			];
		},
	},

	outstreamVideoSettings: {
		name: 'Outstream video settings',
		availForNode(node) {
			return !(node && node.isBidderNode);
		},

		getTagFields() {
			return [
				{
					type: 'String',
					name: 'mediaType',
					description: 'Media type',
					hasOptions: true,
					defaultValue: null,
					options: [
						{
							name: 'outstream',
							label: 'Outstream',
						},
						{
							name: 'bannerOutstream',
							label: 'Banner + Outstream',
						},
					],
				},
				...sharedVideoFields,
				{
					type: 'String',
					name: 'placement',
					description: 'Placement of video',
					defaultValue: '2',
					hasOptions: true,
					isRequired: true,
					options: [
						['2', 'In-banner'],
						['3', 'In-article'],
						['4', 'In-feed'],
						['5', 'Interstitial/Floating'],
					].map(([name, label]) => ({ name, label })),
				},
				{
					type: 'String',
					name: 'plcmt',
					description: 'Placement of video (OpenRTB 2.6 \'plcmt\')',
					defaultValue: '4',
					hasOptions: true,
					isRequired: true,
					options: [
						['3', 'Interstitial'],
						['4', 'No Content/Standalone'],
					].map(([name, label]) => ({ name, label })),
				},
				{
					type: 'Number',
					name: 'maxduration',
					description: 'Max duration',
					defaultValue: 3600,
				},
				{
					type: 'String',
					name: 'adText',
					description: 'Ad text',
					defaultValue: 'Ad',
				},
				{
					type: 'String',
					name: 'skipText',
					description: 'Skip text',
					defaultValue: 'Video can be skipped in %%TIME%% seconds',
				},
				{
					type: 'String',
					name: 'skipButtonText',
					description: 'Skip button text',
					defaultValue: 'Skip',
				},
				{
					type: 'Boolean',
					name: 'allowOverride',
					description: 'Allow VAST to override player skip settings',
					defaultValue: false,
				},
				{
					type: 'Number',
					name: 'expandTime',
					description: 'Expand time (ms)',
					defaultValue: 0, // i.e expand disabled
				},
				{
					type: 'Boolean',
					name: 'enableMaxHeight',
					description: 'Enable max height',
					defaultValue: false,
				},
				{
					// Essentially maps to disableCollapse option for appnexus renderer settings
					type: 'Object',
					name: 'endBehavior',
					description: 'End behavior',
					defaultValue: {},
					fields: [
						{
							type: 'Boolean',
							name: 'collapse',
							description: 'Collapse',
							defaultValue: false,
						},
						{
							type: 'Boolean',
							name: 'replay',
							description: 'Replay',
							defaultValue: false,
						},
						{
							type: 'Boolean',
							name: 'hideControlBar',
							description: 'Hide control bar',
							defaultValue: false,
						},
					],
				},
				{
					type: 'Boolean',
					name: 'forceBannerAdserverTargeting',
					description: 'Force banner adserver targeting',
					defaultValue: false,
				},
			];
		},
	},

	instreamVideoSettings: {
		name: 'Instream video settings',
		availForNode(node) {
			return !(node && node.isBidderNode);
		},
		getTagFields() {
			return [
				...sharedVideoFields,
				{
					type: 'String',
					name: 'plcmt',
					description: 'Placement of video (OpenRTB 2.6 \'plcmt\')',
					defaultValue: '1',
					hasOptions: true,
					isRequired: true,
					options: [
						['1', 'Instream'],
						['2', 'Accompanying Content'],
					].map(([name, label]) => ({ name, label })),
				},
				{
					type: 'Object',
					name: 'api',
					description: 'Supported APIs',
					defaultValue: {},
					fields: [
						{
							type: 'Boolean',
							name: '1',
							description: 'VPAID 1.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '2',
							description: 'VPAID 2.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '3',
							description: 'MRAID 1.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '4',
							description: 'ORMMA',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '5',
							description: 'MRAID 2.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '6',
							description: 'MRAID 3.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '7',
							description: 'OMID 1.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '8',
							description: 'SIMID 1.0',
							defaultValue: true,
						},

					],
				},
				{
					type: 'Array',
					name: 'mimes',
					description: 'Supported MIME types',
					defaultValue: ['video/mp4', 'video/webm', 'video/ogg', 'application/javascript'].map((s) => ({
						mime: s,
					})),
					fields: [{
						type: 'String',
						name: 'mime',
						description: 'Mime type',
						defaultValue: '',
					}],
				},
				{
					type: 'Object',
					name: 'protocols',
					description: 'Supported video protocols',
					defaultValue: {},
					fields: [
						{
							type: 'Boolean',
							name: '1',
							description: 'VAST 1.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '2',
							description: 'VAST 2.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '3',
							description: 'VAST 3.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '4',
							description: 'VAST 1.0 Wrapper',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '5',
							description: 'VAST 2.0 Wrapper',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '6',
							description: 'VAST 3.0 Wrapper',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '7',
							description: 'VAST 4.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '8',
							description: 'VAST 4.0 Wrapper',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '9',
							description: 'DAAST 1.0',
							defaultValue: true,
						},
						{
							type: 'Boolean',
							name: '10',
							description: 'DAAST 1.0 Wrapper',
							defaultValue: true,
						},

					],
				},
				{
					type: 'Number',
					name: 'maxduration',
					description: 'Max duration',
					defaultValue: 60,
				},
			];
		},
	},
	nativeSettings: {
		name: 'Native settings',
		availForNode(node) {
			return !(node && node.isBidderNode);
		},
		getTagFields() {
			const res = [
				{
					type: 'String',
					name: 'nativeMode',
					description: 'Mode',
					defaultValue: 'disabled',
					hasOptions: true,
					isRequired: true,
					options: [
						{
							name: 'disabled',
							label: 'Disabled',
						},
						{
							name: 'enabled',
							label: 'Enabled',
						},
						{
							name: 'exclusive',
							label: 'Native only (will disable banner format)',
						},
					],
				},
				{
					type: 'HTML',
					name: 'adTemplate',
					description: 'Ad Template',
				},
				{
					type: 'String',
					name: 'rendererUrl',
					description: 'Renderer URL',
				},
				{
					type: 'Boolean',
					name: 'sendTargetingKeys',
					description: 'Enable "sendTargetingKeys" (only for legacy line-item setups)',
				},
				...NATIVE_FIELDS.map((fld) => ({
					type: 'Object',
					name: fld,
					description: fld === 'cta' ? 'Call to Action' : splitToDesc(fld),
					fields: [
						{
							type: 'Boolean',
							name: 'disabled',
							description: 'Disable asset',
						},
						{
							type: 'Boolean',
							name: 'required',
							description: 'Required',
						},
						...(NATIVE_IMAGE_FIELDS.includes(fld) ? [
							{
								type: 'Number',
								name: 'width',
								description: 'Width',
							},
							{
								type: 'Number',
								name: 'height',
								description: 'Height',
							},
							{
								type: 'Boolean',
								name: 'useAspec',
								description: 'Allow larger images with same aspect ratio',
							},
						] : [
							{
								type: 'Number',
								name: 'length',
								description: 'Length',
							},
						]),
					],
				})),
			];
			return renameToInternalParams(res);
		},
	},
};

module.exports = { PrebidDataObjects };
