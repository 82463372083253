import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classes from '../../api/classes';
import type { Motd as MotdType } from '../../api/classes';

const { Motd } = classes;

export function useMotdQuery() {
	return useQuery<MotdType>({
		queryKey: ['motd'],
		queryFn: async () => {
			return await Motd.listOne();
		},
		placeholderData: new Motd(),
	});
}

export function useUpdateMotdMutation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['motd'],
		mutationFn: async (motd: MotdType) => {
			if (!motd.message) {
				throw new Error('Message is required');
			}
			return await motd.update();
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['motd'] });
		},
	});
}

export function useRemoveMotdMutation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (motd: typeof Motd) => {
			const defaults = new Motd();
			return await motd.update({
				title: defaults.title,
				message: defaults.message,
				status: defaults.status,
				closable: defaults.closable,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['motd'] });
		},
	});
}
