import React from 'react';
import { Banner, BannerProps } from '../../components/Banner';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Grid } from '@mui/material';
import MiscUtils from '../../lib/miscUtils';
import { useMotdQuery } from '../../pages/AdminNotifications/query';
import { stores } from '../../stores';

// "Message of the day" banner
export function MOTD({
	className,
}: {
	className?: string;
}) {
	const { data: motd } = useMotdQuery();
	const [motdHidden, setMotdHidden] = useLocalStorage<number | null>('motdHidden', null);

	const isSet = motd && motd.message;
	const isHidden = motd && MiscUtils.strHash(JSON.stringify(motd)) === motdHidden;
	const isVisible = isSet && !isHidden && ((motd.showForAdmins && stores.identity.isAdministrator()) || (motd.showForUsers && !stores.identity.isAdministrator()));

	const onClose = () => {
		setMotdHidden(MiscUtils.strHash(JSON.stringify(motd)));
	};

	return isVisible && (
		<Grid item xs={12}>
			<Banner
				{...motd}
				onClose={motd.closable ? onClose : undefined}
				className={className}
			/>
		</Grid>
	);
}
