const _ = require('lodash');
const { distribute } = require('./misc');
const { ADS_OPTI_FLOOR } = require('./sharedConstants');
const { ADS_OPTI_FLOOR_IDX } = require('../reportData/constants');

const MAX_ACTIVE_RULES = 200;
const MAX_TOTAL_RULES = 1000; // According to ChatGPT...
const CPM_DECIMALS = 2; // Number of decimals in CPM, it appears GAM limits it to 2

const Constants = {
	NUM_KEY_VALUES: MAX_ACTIVE_RULES, // One targeting value for each of the possible max 200 rules
	KEY_DISPLAY_NAME: 'Relevant Digital Floor Bucket',
	KEY_NAME: 'rlv_floor_bucket',
	KEY_SET_DISPLAY_NAME: 'Relevant Digital Floor Set',
	KEY_SET_NAME: 'rlv_floor_set',
	OPTI_VALUE: 'opti', // rlv_floor_bucket=opti => Let Google optimize
	RULE_ENABLED: 1925346054, // GAM-"code" for status active on rules
	RULE_DISABLED: 807292011, // GAM-"code" for status inactive on rules
	TYPE_FLOOR: 66989036, // "Set floor prices"
	TYPE_TARGET: 1827576431, // "Set target CPMs"
	TYPE_OPTI: 335091975, // Let Google optimize floor prices
};

const roundCpm = (n) => Math.round((n || 0) * (10 ** CPM_DECIMALS)) / (10 ** CPM_DECIMALS);

Object.assign(Constants, {
	ALL_NUMBERS: _.range(1, Constants.NUM_KEY_VALUES + 1), // [1, ..., 200]
});

const DEFAULT_VALUES = {
	keyId: undefined, // Id for 'rlv_floor_bucket' targeting key
	valIds: [], // Ids for the 200 targeting values (hence the targeting value is +1 of the index)
	currency: undefined, // Network currency (can't be changed in GAM so we just read it once)
	rules: [], // Our 'rlv_floor_bucket' targeted rules
	activeCount: undefined, // Total number of active rules (including other rules)
	totalCount: undefined, // Total number of active+inactive rules (including other rules)
	allNames: [], // Names of all rules, necessary to keep as GAM doesn't allow 2+ active rules with the same name.
	rulesHash: undefined, // Hash-value of the "rules state", to stop operations created on obsolete states.
};

const medianCache = {}; // Used by function below

/**
 * This function tries to figure out which "Median CPM" that was used when the existing rules was created. It does
 * so by some inefficient testing, hence the caching. There is probably some smarter way to figure this out. */
const getMatchingMedian = function (approx, midCpm, largestCpm, len) {
	// eslint-disable-next-line prefer-rest-params
	const key = [...arguments].join();
	if (key in medianCache) {
		return medianCache[key];
	}
	const minDiff = 1 / (10 ** CPM_DECIMALS);
	for (let i = 0; i < 1000; i += 1) {
		for (const cand of [approx + (minDiff * i), approx - (minDiff * i)]) {
			if (cand > 0) {
				const arr = distribute(cand, largestCpm / cand, len, { numDecimals: CPM_DECIMALS });
				if (roundCpm(midCpm) === roundCpm(arr[len / 2])) {
					medianCache[key] = roundCpm(cand);
					return medianCache[key];
				}
			}
		}
	}
	medianCache[key] = approx;
	return approx;
};

/** Current state of price rules in GAM, created by reading rule-information from GAM.
  * The exception is when calling DfpPriceFloorState.buildFromSettings() as that will create
  * a "preview" state, showing how the state is supposed to be after applying some specific changes. */
class DfpPriceFloorState {
	constructor(settings) {
		Object.assign(this, {
			..._.cloneDeep(DEFAULT_VALUES),
			...settings,
		});
		this.rules = _.orderBy(this.rules, [(r) => (r.active ? 0 : 1), 'cpm']);
		this.rulesByNr = _.keyBy(this.rules, 'targNr');
	}

	/** Create a cloned state, optionally with some other settings 'withSetting' */
	clone(withSettings) {
		return new DfpPriceFloorState(_.cloneDeep({
			..._.pick(this, _.keys(DEFAULT_VALUES)),
			...withSettings,
		}));
	}

	/** True if we've created/loaded all key-values */
	get keyValuesDone() {
		return this.keyId && this.valIds.length >= Constants.NUM_KEY_VALUES;
	}

	/** Targeting values (1..200) no yet used by any price rule */
	get availableNumbers() {
		return Constants.ALL_NUMBERS.filter((nr) => !this.rulesByNr[nr]);
	}

	/** True if we've both loaded all key-values AND loaded the current price rules state from GAM. */
	get ruleInfoReady() {
		return this.keyValuesDone && _.isNumber(this.totalCount);
	}

	/** Maximum active rules (normally 200) */
	get maxActive() {
		return Math.max(MAX_ACTIVE_RULES, this.activeCount || 0);
	}

	/** Maximum total rules (normally 1000) */
	get maxTotal() {
		return Math.max(MAX_TOTAL_RULES, this.totalCount || 0);
	}

	/** Number of more rules that can be added */
	get availableTotal() { // Assume new rules are active
		return this.ruleInfoReady ? this.maxTotal - this.totalCount : null;
	}

	/** Number of more ACTIVE rules that can be added */
	get availableToActivate() {
		return this.ruleInfoReady ? this.maxActive - this.activeCount : null;
	}

	/** Number of rules that can be "enabled" either by activating existing de-activated rules
	 * or by creating new active rules. */
	get availableToEnable() {
		if (!this.ruleInfoReady) {
			return null;
		}
		let res = this.availableToActivate;
		const inactive = this.inactiveRules.length;
		const toAdd = res - inactive;
		if (toAdd > 0) {
			const tooMany = (this.totalCount + toAdd) - this.maxTotal;
			if (tooMany > 0) {
				res -= tooMany;
			}
		}
		return res;
	}

	/** Array of all of our active 'rlv_floor_bucket' targeted rules */
	get activeRules() {
		return _.filter(this.rules, 'active');
	}

	/** All rules except GAM optimization rule */
	get activeCpmRules() {
		return this.activeRules.filter((r) => r.type !== Constants.TYPE_OPTI);
	}

	/** The GAM optimization rule */
	get activeOptiRule() {
		return _.find(this.activeRules, { type: Constants.TYPE_OPTI });
	}

	/** Array of all of our inactive 'rlv_floor_bucket' targeted rules */
	get inactiveRules() {
		return _.filter(this.rules, (r) => !r.active);
	}

	getFloorRangeMapping(seenRuleIds) {
		const { activeCpmRules, activeOptiRule } = this;
		const seen = _.keyBy(seenRuleIds);
		const seenCpmRules = activeCpmRules.filter(({ id }) => seen[id]);
		const floorRanges = [];
		const idToRangeIdx = seenRuleIds.includes(activeOptiRule?.id)
			? { [activeOptiRule.id]: ADS_OPTI_FLOOR_IDX }
			: {};

		// Similar conversion as in FloorValue(double val)
		const toHbaFloor = (cpm) => {
			if (cpm < 300) {
				const res = Math.floor(cpm * 100);
				return cpm && !res ? 1 : res;
			}
			return Math.floor(30000 + ((cpm - 300) * 10.0));
		};
		// Similar conversion as in FloorValue::toDouble()
		const fromHbaFloor = (hbaFloor) => (hbaFloor < 30000 ? hbaFloor / 100.0 : 300.0 + ((hbaFloor - 30000) / 10.0));

		seenCpmRules.forEach(({ id, cpm }, idx) => {
			const nextRule = seenCpmRules[idx + 1];
			const belowCpm = nextRule ? fromHbaFloor(toHbaFloor(nextRule.cpm)) : cpm + 1000; // 1000 should be enough...
			floorRanges.push(belowCpm);
			idToRangeIdx[id] = idx;
		});
		return { floorRanges, idToRangeIdx };
	}

	/** Convert a rule (already) parsed from a GAM response into a more user-friendly format we'll use for
	  * storage in the .rules array. */
	ruleFromGam(obj) {
		const cpm = obj.type === Constants.TYPE_OPTI
			? ADS_OPTI_FLOOR
			: Math.max(parseInt(obj.cpmMicros, 10) / 1000000, 0);
		const doThrow = (msg) => { throw Error(`${msg} ('${obj.name}' with id '${obj.id}')`); };
		if (Number.isNaN(cpm)) {
			doThrow('Can\'t read CPM of price rule');
		}
		const idx = this.valIds.indexOf(obj.valueId);
		if (idx < 0) {
			doThrow('Can\'t interpret targeting value of price rule');
		}
		return {
			..._.pick(obj, ['id', 'name', 'type', 'orgPayload']),
			active: obj.status === Constants.RULE_ENABLED,
			cpm,
			targNr: idx + 1,
		};
	}

	/** Convert a rule from the format we store in the .rules array into values that we can use when setting the
	  * payload for a rule in UI API calls to GAM. */
	ruleToGam(obj) {
		const valueId = this.valIds[obj.targNr - 1];
		if (!valueId) {
			throw Error('targNr invalid');
		}
		return {
			id: undefined,
			..._.pick(obj, ['id', 'name', 'type']),
			..._.pick(this, ['keyId', 'currency']),
			valueId,
			cpmMicros: Math.round(Math.max(obj.cpm, 0) * 1000000).toString(),
			status: obj.active ? Constants.RULE_ENABLED : Constants.RULE_DISABLED,
		};
	}

	/** The bucket-array used in the tag .js files. Example: if we have 3 rules with cpms [0,1,2] and targeting
	 * keys [1,3,5], then the array will look like [null, 0.0, null, 3.0, null 5.0] */
	get bucketArray() {
		const { activeRules } = this;
		if (!activeRules.length) {
			return [];
		}
		const { targNr: maxTargNr } = _.maxBy(activeRules, 'targNr');
		const arr = Array(maxTargNr).fill(null);
		this.activeRules.forEach(({ targNr, cpm }) => {
			arr[targNr] = cpm;
		});
		return arr;
	}

	/** "Median" CPM of our active rules. This is not 100% accurate as when having an even number of active rules
	 *  we'll try to figure out which "median" value that was selected when creating the rules. This is as
	 *  our distribute() function won't really enforce any median value. */
	get medianActiveCpm() {
		const { activeCpmRules: active } = this;
		if (!active.length) {
			return 0;
		}
		let res = active[Math.floor(active.length / 2)].cpm;
		const even = active.length % 2 === 0;
		if (even) {
			res = (res + active[(active.length / 2) - 1].cpm) / 2;
		}
		res = roundCpm(res);
		if (even) {
			res = getMatchingMedian(res, active[active.length / 2].cpm, _.last(active).cpm, active.length);
		}
		return res;
	}

	/** Guess the "range multiplier" that was used when the rules were created. */
	guessApproxMultiplier() {
		const { activeCpmRules, medianActiveCpm } = this;
		if (activeCpmRules.length <= 1) {
			return 0;
		}
		let mult = _.last(activeCpmRules).cpm / medianActiveCpm;
		mult = Math.min(Math.max(mult, 1.1), 1000);
		return roundCpm(mult);
	}

	/** Summaries the "effect" of applying a set of operations on the rules (for UI/display purpose) */
	getOpsSummary(ops) {
		const res = {
			toCreate: 0,
			toActivate: 0,
			toDeactivate: 0,
			toUpdateCpm: 0,
		};
		Object.entries(ops).forEach(([targNr, diff]) => {
			const old = this.rulesByNr[targNr];
			if (!old) {
				res.toCreate += 1;
			} else {
				if ('active' in diff && diff.active !== old.active) {
					res[diff.active ? 'toActivate' : 'toDeactivate'] += 1;
				}
				if ('cpm' in diff && diff.cpm !== old.cpm) {
					res.toUpdateCpm += 1;
				}
			}
		});
		return res;
	}

	/** Creates a "preview state" showing how the rule state will look like if applying the changes in 'settings'
	  * we'll also return the operations necessary to reach that new state.
	  * @param {*} settings An object { rulesToCreate, medianCpm, multiplier } where 'rulesToCreate' how many
	  * active ones of our rules there should be after the operation. 'medianCpm' is the "center-point" used in the
	  * call to distribute(...) when selecting CPMs and multiplier is how many times larger the highest CPM should be
	  * than 'medianCpm'.
	  * @returns An object { newState, ops } where 'newState' is the "preview state" and 'ops' is the necessary
	  * changes ("operations") needed to reach that state, which will be used as input to
	  * DfpAdsFloorManager.updatePriceRules().
	  */
	buildFromSettings(settings) {
		const { rulesToCreate, medianCpm, multiplier, gamOptiEnabled } = settings;
		const {
			rules, activeRules, inactiveRules, activeCount, totalCount, rulesByNr, allNames,
		} = this;
		const allNamesMap = _.keyBy(allNames.map((s) => s.toLowerCase()));
		const getUniqueName = ({ targNr, type }) => {
			for (let i = 0; ; i += 1) {
				const desc = type === Constants.TYPE_OPTI ? 'GAM opti rule' : 'price bucket';
				const name = `Relevant Yield ${desc} ${targNr}${i ? ` (${i})` : ''}`;
				if (!allNamesMap[name.toLowerCase()] || rulesByNr[targNr]?.name === name) {
					return name;
				}
			}
		};

		const numToAdd = rulesToCreate - activeRules.length;
		let ops = {};
		// eslint-disable-next-line no-return-assign
		const addOp = (nr, obj) => Object.assign(ops[nr] = ops[nr] || {}, obj);
		let newRules = [];
		if (numToAdd > 0) {
			// Activate
			const activateOps = inactiveRules.slice(0, numToAdd).map((r) => addOp(r.targNr, {
				active: true,
			}));
			// Add
			newRules = this.availableNumbers.slice(0, numToAdd - activateOps.length).map((nr) => addOp(nr, {
				active: true,
				targNr: Number(nr),
			}));
		} else if (numToAdd < 0) {
			// De-activate
			[...activeRules].reverse().slice(0, -numToAdd).forEach((r) => addOp(r.targNr, { active: false }));
		}
		const updateRules = [..._.cloneDeep(rules),	...newRules];
		const applyUpdates = () => updateRules.forEach((r) => Object.assign(r, ops[r.targNr]));
		applyUpdates();
		const newActiveRules = _.filter(updateRules, 'active');
		const cpmRuleCount = Math.max(newActiveRules.length - (gamOptiEnabled ? 1 : 0), 0);
		const cpms = distribute(medianCpm, multiplier, cpmRuleCount, { numDecimals: CPM_DECIMALS });
		const datas = [
			// Add single gam-optimize rule
			...(newActiveRules.length && gamOptiEnabled ? [{ type: Constants.TYPE_OPTI, cpm: ADS_OPTI_FLOOR }] : []),
			// And the "normal" ones
			...cpms.map((cpm) => ({ type: Constants.TYPE_FLOOR, cpm })),
		];
		_.sortBy(newActiveRules, 'targNr').forEach((r, idx) => {
			const data = datas[idx];
			addOp(r.targNr, data);
		});
		applyUpdates();
		updateRules.forEach((r) => addOp(r.targNr, { name: getUniqueName(r) }));
		applyUpdates();

		// Clean up operations without changes
		ops = _(ops)
			.mapValues((obj, targNr) => _.pickBy(obj, (v, k) => !_.isEqual(v, rulesByNr[targNr]?.[k])))
			.omitBy(_.isEmpty)
			.value();

		const newState = this.clone({
			rules: updateRules,
			activeCount: activeCount + _.filter(ops, { active: true }) - _.filter(ops, { active: false }),
			totalCount: totalCount + newRules.length,
		});
		return { newState, ops };
	}
}

DfpPriceFloorState.Constants = Constants;

module.exports = DfpPriceFloorState;
