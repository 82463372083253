import { Close } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';
import { CloseButton } from './CloseButton';

export type BannerProps = {
	title?: string;
	message: string | React.ReactNode;
	status?: 'default' | 'info' | 'warning' | 'error';
	action?: {
		label: string;
		onClick: () => void;
	};
	onClose?: () => void;
	className?: string;
};

export function Banner({
	title,
	message,
	status = 'default',
	onClose,
	className,
}: BannerProps) {
	return (
		<div
			className={clsx(
				'relative flex gap-2 rounded-md p-4 text-sm shadow justify-start',
				{
					'bg-white border border-gray-200': status === 'default',
					'bg-success-blue-5 border border-success-blue-60': status === 'info',
					'bg-warning-yellow-5 border border-warning-yellow-70': status === 'warning',
					'bg-failure-red-5 border border-failure-red-60': status === 'error',
				},
				{
					'text-gray-700': status === 'default',
					'text-success-blue-80': status === 'info',
					'text-warning-yellow-80': status === 'warning',
					'text-failure-red-80': status === 'error',
				},
				className,
			)}
		>
			<div className="flex flex-col gap-2 flex-grow">
				{title && (
					<div className={clsx(
						'font-medium',
						{
							'text-gray-900': status === 'default',
							'text-success-blue-80': status === 'info',
							'text-warning-yellow-80': status === 'warning',
							'text-failure-red-80': status === 'error',
						},
					)}>
						{title}
					</div>
				)}
				<div className="flex-grow">{message}</div>
			</div>
			{onClose && (
				<button
					className={clsx(
						'h-6 w-6 rounded-md',
						{
							'hover:bg-gray-100': status === 'default',
							'hover:bg-night-blue-100': status === 'info',
							'hover:bg-yellow-100': status === 'warning',
							'hover:bg-red-100': status === 'error',
						},
						className,
					)}
					onClick={onClose}
				>
					<Close className="size-5 text-inherit opacity-40 hover:opacity-100 isolate" />
				</button>
			)}
		</div>
	);
}
