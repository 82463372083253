export const drawerWidth = 270;
/**
 * Configurations for the sidebar component.
 */

export const SECTION = {
	REPORTS: 'reports',
	HB_MANAGEMENT: 'hb-management',
	CONFIGURATION: 'settings',
	ADMIN: 'admin',
	AUDIENCE: 'audience',
};

/**
 * The "simple" menu items,
 * that doesn't have any complicated access requirements etc
 */
export const audienceMenuItems = [
	{
		path: '/settings/audience-configurations',
		title: 'Audience configurations',
	},
	{
		path: '/settings/segment-list',
		title: 'Segment list',
	},
	{
		path: '/settings/revenue-rules',
		title: 'Revenue rules',
	},
	{
		path: '/settings/cint-edit',
		title: 'CINT segments',
	},
	{
		path: '/settings/my-segments',
		title: 'My segments',
	},
];
export const adminSettingsMenuItems = [
	{
		path: '/settings/programmatic-configurations/',
		title: 'Integrations',
	},
	{
		path: '/settings/events',
		title: 'Events',
	},
	{
		path: '/settings/advertiser-mappings',
		title: 'Demand Mappings',
	},
	{
		path: '/settings/portfolio-edit',
		title: 'Sales portfolios',
	},
	{
		path: '/settings/tag-data-edit?type=programmaticMasterTag',
		title: 'Global programmatic tag',
	},
	{
		path: '/admin/notifications',
		title: 'Notifications',
	},
];

export const adminHbManagementMenuItems = [
	{
		path: '/hb-management',
		title: 'Global HB management',
	},
	{
		path: '/settings/placement-tag-fields',
		title: 'Placement tag fields',
	},
	{
		path: '/settings/pb-config-tag-fields',
		title: 'Prebid config tag fields',
	},
	{
		path: '/settings/site-tag-fields',
		title: 'Site tag fields',
	},
];

export const adminMenuItems = [
	{
		path: '/settings/doc-templates',
		title: 'Document templates',
	},
	{
		path: '/settings/report-templates',
		title: 'Report and dashboard templates',
	},
	{
		path: '/reports/missing-ssp-traffic/add',
		title: 'Missing SSP traffic',
	},
	{
		path: '/admin/jobs',
		title: 'Jobs',
	},
	{
		path: '/admin/cdn-edit',
		title: 'CDN',
	},
];

/**
 * Used for expanding section logic in the sidebar on mount.
 * Some paths may not be coherent with the menu items.
 * For example missing-ssp-traffic has path reports/missing-ssp-traffic,
 * but it's under the "Admin" section in the sidebar.
 * This constant is a way of handling these cases.
 */
export const NON_ADHERING_TO_SECTION_RULES_PAGES = {
	'doc-templates': 'admin',
	'missing-ssp-traffic': 'admin',
	'placement-tag-fields': 'hb-management',
	'site-tag-fields': 'hb-management',
	'pb-config-tag-fields': 'hb-management',
};
