/* eslint-disable react/jsx-one-expression-per-line, react/forbid-prop-types, react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SyncIcon from '@mui/icons-material/SyncAlt';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import DfpPriceFloorState from 'relevant-shared/misc/dfpPriceFloorState';
import ExternalSystemEdit from '../ExternalSystemEdit';
import Credentials from '../Credentials';
import ExpandSelector from '../ExpandSelector';
import PopupSelector from '../PopupSelector';
import ReactUtils from '../../lib/reactUtils';
import Checkbox from '../Checkbox';
import TextField from '../TextField';
import OperationWrapper from '../OperationWrapper';
import Disabler from '../Disabler';
import DataGrid from '../DataGrid';
import classes from '../../api/classes';
import Base from '../../layouts/Base';
import { SimpleAlert, FormOf } from '../Wrappers';
import { ConfirmDialog } from '../ConfirmDialog';
import { triggerToast } from '../Toast/misc';
import { ActionButton } from '../ActionButton/ActionButton';
import Snippets from './snippets';
import msgChannel from './msgChannel';

const { Constants } = DfpPriceFloorState;
const { ExchangeRate } = classes;

function GoogleBrowserConnection({ model, field }) {
	return (
		<Credentials
			model={model.credentials}
			field={ReactUtils.subFld(field, 'credentials')}
			noRequire
		/>
	);
}

GoogleBrowserConnection.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
};

const CHANGE_STATUSES = {
	nochange: ['No change', 'gray'],
	added: ['New rule', 'blue'],
	activated: ['Activated', 'green'],
	deactivated: ['Deactivated', 'red'],
	changed: ['Changed', 'orange'],
};

const triggerConfirm = (text) => Base.renderGlobal((closeFn) => (
	<ConfirmDialog
		open
		text={text}
		onAny={closeFn}
	/>
));

const showGoogleError = ({ googleScreenshot }) => googleScreenshot && Base.renderGlobal((done) => (
	<PopupSelector
		forceExpanded
		size="md"
		onStateUpdate={({ expanded }) => expanded === false && done()}
	>
		<Card>
			<CardContent>
				<Typography variant="h2">
					The Google login process failed with the screenshot below
				</Typography>
				<img alt="screenshot" style={{ width: '100%' }} src={googleScreenshot} />
			</CardContent>
		</Card>
	</PopupSelector>
));

class DfpAdsFloorManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = { priceFloorState: new DfpPriceFloorState() };
	}

	async onApplyChanges(loadFn) {
		const { system } = this.props;
		const { priceFloorState, previewState } = this.state;

		const text = Snippets.updateMessage(
			priceFloorState.getOpsSummary(previewState.ops),
		);
		if (!await triggerConfirm(text)) {
			return;
		}
		await loadFn(async () => {
			await system.updatePriceRules({
				...msgChannel(),
				ops: previewState.ops,
				rulesHash: priceFloorState.rulesHash,
			});
			triggerToast({
				message: 'Updates successfully done',
				status: 'success',
				timeout: 15,
			});
		});
	}

	async onSetupKeyValues(loadFn) {
		const { system } = this.props;
		const { priceFloorState } = this.state;
		if (priceFloorState.keyValuesDone) {
			if (!await triggerConfirm(Snippets.keyValReSyncMessage)) {
				return;
			}
		}
		if (!await triggerConfirm(Snippets.keyValCreateMessage)) {
			return;
		}
		await loadFn(async () => {
			await system.updateKeyValues(msgChannel());
			triggerToast({
				message: 'Key-values successfully created',
				status: 'success',
				timeout: 15,
			});
		});
	}

	async onSetupExtraKeyValues(loadFn) {
		const { system } = this.props;
		const { priceFloorState: st } = this.state;
		if (!await triggerConfirm(Snippets.optionalKeyValAnyMessage(st))) {
			return;
		}
		await loadFn(async () => {
			await system.updateExtraKeyValues(msgChannel());
			triggerToast({
				message: 'Optional key-values successfully created',
				status: 'success',
				timeout: 15,
			});
		});
	}

	getPriceRuleEditBlockedReason() {
		const { form, system } = this.props;
		if (system.isNew) {
			return 'isNew';
		}
		const ready = !form.hasErrors(true);
		if (!ready) {
			return 'notReady';
		}

		const connOf = (obj) => obj?.adsFloorManager?.browserConnection;
		if (!_.isEqual(connOf(system), connOf(system.getOriginalApiObject()))) {
			return 'connectionChanged';
		}
		return null;
	}

	getChangeStatus({ targNr }) {
		const { priceFloorState, previewState, editMode } = this.state;
		if (!editMode || !previewState) {
			return 'nochange';
		}
		const newRule = previewState.newState.rulesByNr[targNr];
		const oldRule = priceFloorState.rulesByNr[targNr];
		if (!oldRule) {
			return 'added';
		}
		if (newRule.active !== oldRule.active) {
			return newRule.active ? 'activated' : 'deactivated';
		}
		if (newRule.cpm !== oldRule.cpm) {
			return 'changed';
		}
		return 'nochange';
	}

	getPreviewSettings() {
		return {
			..._.mapValues(_.pick(this.state, ['rulesToCreate', 'medianCpm', 'multiplier']), Number),
			..._.pick(this.state, ['gamOptiEnabled']),
		};
	}

	async loadFloorState() {
		const { system } = this.props;
		const st = new DfpPriceFloorState(system.isNew ? null : await system.getPriceRuleState({
			includeOpState: true,
		}));
		const { medianActiveCpm, activeRules, activeOptiRule } = st;
		if (!medianActiveCpm && !this.rates && st.currency && st.currency !== 'EUR') {
			const now = new Date();
			const [{ rates }] = _.values(await ExchangeRate.getExchangeRates({ firstDate: now, lastDate: now }));
			this.rates = _.mapValues(_.keyBy(rates, 'currency'), 'rate');
		}
		const DEFAULT_EUR_CPM = 2;
		const DEFAULT_SAVE_SPACE_RULES = 10; // Don't suggest using up all available rules by default
		const DEFAULT_MULTIPLIER = 15;

		this.setState({
			priceFloorState: st,
			medianCpm: medianActiveCpm || (this.rates?.[st.currency] || 1) * DEFAULT_EUR_CPM,
			rulesToCreate: activeRules.length || Math.max(st.availableToEnable - DEFAULT_SAVE_SPACE_RULES, 0),
			multiplier: st.guessApproxMultiplier() || DEFAULT_MULTIPLIER,
			gamOptiEnabled: activeRules.length ? !!activeOptiRule : true,
			previewSettings: null,
			previewState: null,
		});
	}

	createPreview() {
		const { priceFloorState } = this.state;
		const previewSettings = this.getPreviewSettings();
		this.setState({
			previewSettings,
			previewState: priceFloorState.buildFromSettings(previewSettings),
		});
	}

	renderSummary(vals, exclude) {
		const { priceFloorState: st } = this.state;
		const rows = {
			keyName: ['Targeting key', Constants.KEY_NAME],
			kvsReady: [
				'Key-values ready',
				st.valIds.length ? `${st.valIds.length}/${Constants.NUM_KEY_VALUES}` : '-',
			],
			extraKvsReady: [
				'Optional Key-values ready',
				st.extraKvsCreated ? `${Constants.KEY_SET_NAME} created` : '-',
			],
			activeRules: [
				'Active rules',
				st.rules.length ? `${st.activeRules.length}/${st.rules.length}` : '-',
			],
			medianCpm: [
				'Median CPM of Active rules',
				st.activeRules.length ? st.medianActiveCpm : '-',
			],
			availableToEnable: [
				'More rules that can be enabled',
				st.ruleInfoReady ? st.availableToActivate : '-',
			],

		};
		const entries = Object.entries(vals ? _[exclude ? 'omit' : 'pick'](rows, vals) : rows);
		return (
			<table>
				<tbody>
					{entries.map(([key, [title, value]]) => (
						<tr key={key}>
							<td style={{ paddingRight: 15 }}><i>{title}: </i></td>
							<td>{value}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	renderOpStatus() {
		const { priceFloorState } = this.state;
		const { error } = priceFloorState?.opState || {};
		if (!error) {
			return undefined;
		}
		return (
			<Alert severity="error" sx={{ width: '100%', ml: 3, mt: 2 }}>
				The following error occured during the last update of price floors in Google Ad Manager
				<div>
					<b>
						{error}
					</b>
				</div>
			</Alert>
		);
	}

	renderKvSyncButtons({ loadFn }) {
		const { priceFloorState: st } = this.state;
		return (
			<Grid>
				<Grid item xs={12} mb={1}>
					<ActionButton
						label="Re-sync Key-values via API"
						icon={<SyncIcon />}
						onClick={() => this.onSetupKeyValues(loadFn)}
						variant="outlined"
					/>
				</Grid>
				<Grid>
					<ActionButton
						label={`${st.extraKvsCreated ? 'Re-sync' : 'Set up'} optional Key-values via API`}
						icon={st.extraKvsCreated ? <SyncIcon /> : <AddIcon />}
						onClick={() => this.onSetupExtraKeyValues(loadFn)}
						variant="outlined"
					/>
				</Grid>
			</Grid>
		);
	}

	renderEdit({ field, form }, op) {
		const { system } = this.props;
		const {
			priceFloorState: st, editMode, previewSettings, previewState,
		} = this.state;
		const loadFn = (fn) => op.reload(async () => {
			try {
				await fn();
			} catch (e) {
				await showGoogleError(e);
				throw e;
			} finally {
				await this.loadFloorState();
				form.resetErrors();
			}
		});
		const previewNeeded = !_.isEqual(previewSettings, this.getPreviewSettings());
		const KEY_SUMMARY_KEYS = ['keyName', 'kvsReady', 'extraKvsReady'];
		return (
			<Grid container spacing={3}>
				{this.renderOpStatus()}
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h2">
								Key values
							</Typography>
							<Box style={{ display: 'flex' }}>
								<Box style={{ flexGrow: 1 }}>
									{this.renderSummary(KEY_SUMMARY_KEYS)}
								</Box>
								{st.keyValuesDone && this.renderKvSyncButtons({ loadFn })}
							</Box>
							{!st.keyValuesDone && (
								<SimpleAlert
									title="Action needed"
									content={Snippets.keyValActionNeeded}
									action={(
										<ActionButton
											label="Set up Key-values via API"
											onClick={() => this.onSetupKeyValues(loadFn)}
											variant="outlined"
										/>
									)}
								/>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Disabler disabled={!st.keyValuesDone}>
						<Card>
							<CardContent>
								<Typography variant="h2">
									Unified pricing rules
								</Typography>
								<Grid container spacing={3}>
									<Grid item xs={4}>
										<Disabler disabled={editMode}>
											{this.renderSummary(KEY_SUMMARY_KEYS, true)}
										</Disabler>
									</Grid>
									<Grid item xs={8}>
										<Grid container spacing={3}>
											<Grid item xs={4}>
												<ActionButton
													label="Reload from GAM"
													icon={<ReplayIcon />}
													onClick={() => loadFn(() => (
														system.refreshPriceRuleState(msgChannel())
													))}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={2}>
												<Checkbox label="Edit" {...field('editMode')} />
											</Grid>
											{editMode && (
												<>
													<Grid item xs={2}>
														<ActionButton
															label="Preview"
															disabled={form.hasErrors(true) || !previewNeeded}
															icon={<VisibilityIcon />}
															onClick={() => this.createPreview()}
															variant="outlined"
														/>
													</Grid>
													<Grid item xs={4}>
														<Tooltip title={!!previewNeeded && 'You must preview your settings first'}>
															<div>
																<ActionButton
																	label="Apply changes in GAM"
																	disabled={form.hasErrors(true)
																		|| previewNeeded
																		|| _.isEmpty(previewState?.ops)}
																	icon={<SaveIcon />}
																	onClick={() => this.onApplyChanges(loadFn)}
																	variant="outlined"
																/>
															</div>
														</Tooltip>
													</Grid>
													<Grid item xs={3}>
														<TextField
															integer
															required
															between={{
																low: 0,
																high: st.activeRules.length + st.availableToActivate,
															}}
															label="Num. active rules"
															{...field('rulesToCreate')}
														/>
													</Grid>
													<Grid item xs={2}>
														<TextField
															float
															required
															between={{ low: 0 }}
															label="Median CPM"
															{...field('medianCpm')}
														/>
													</Grid>
													<Grid item xs={3}>
														<TextField
															float
															required
															between={{ low: 2, high: 1000 }}
															label="Range multiplier"
															{...field('multiplier')}
														/>
													</Grid>
													<Grid item xs={4}>
														<Checkbox
															label="GAM Optimization"
															{...field('gamOptiEnabled')}
														/>
													</Grid>
												</>
											)}
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<DataGrid
											disableColumnMenu
											disableSelectionOnClick
											disableColumnReorder
											hideFooter
											identifier={(row) => row.targNr}
											definitions={[
												{
													key: 'cpm',
													title: `Floor CPM (${st.currency})`,
													sortable: true,
													format: (cpm, { type }) => (type !== Constants.TYPE_OPTI ? cpm : (
														<span style={{ color: 'blue' }}>
															GAM Optimized CPM
														</span>
													)),
												},
												{
													key: 'active',
													title: 'Active',
													sortable: true,
													format: (enabled) => (
														<span style={{ color: enabled ? 'green' : 'red' }}>
															{enabled ? 'Enabled' : 'Disabled'}
														</span>
													),
												},
												{
													key: 'targNr',
													title: 'Targeting Value',
													sortable: true,
												},
												{
													key: 'name',
													title: 'Name',
													sortable: true,
												},
												{
													key: 'change',
													title: 'Change',
													sortable: true,
													transform: (__, row) => this.getChangeStatus(row),
													format: (status) => (([desc, color]) => (
														<span style={{ color }}>
															{desc}
														</span>
													))(CHANGE_STATUSES[status]),
												},
											]}
											data={(editMode && previewState?.newState.rules) || st.rules}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Disabler>
				</Grid>
			</Grid>
		);
	}

	render() {
		const { field, model, form } = this.props;
		const blockReason = this.getPriceRuleEditBlockedReason();
		const autoOpen = !blockReason && window.location.toString().includes('autoOpenFloorSetup');
		return (
			<ExpandSelector title="Floor prices for HBM" expanded={autoOpen}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Checkbox
							label="Enable floor prices in GAM"
							{...field('enabled')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Checkbox
							label="Use local storage for GAM optimized CPM usage"
							help="A random number will be saved once in local storage"
							{...field('useLocalStorage')}
						/>
					</Grid>
					<Grid item xs={12}>
						<ExpandSelector title="Google UI credentials" form={form}>
							<GoogleBrowserConnection
								model={model.browserConnection}
								field={ReactUtils.subFld(field, 'browserConnection')}
							/>
						</ExpandSelector>
					</Grid>
					<Grid item xs={12}>
						<OperationWrapper
							fn={() => this.loadFloorState()}
							disableMode
						>
							<Grid container spacing={3}>
								{this.renderOpStatus()}
								<Grid item xs={12}>
									{this.renderSummary()}
								</Grid>
								<Grid item xs={12}>
									<Disabler disabled={!!blockReason}>
										<PopupSelector
											title="Set up price rules"
											okLabel="Close"
											expanded={autoOpen}
											size="lg"
											content={() => (
												<OperationWrapper
													content={(op) => (
														<FormOf
															model={this.state}
															content={(p) => this.renderEdit(p, op)}
														/>
													)}
													disableMode
												/>
											)}
										/>
									</Disabler>
									{blockReason && (
										<Alert severity="info">
											{Snippets.priceRulesBlocked[blockReason]}
										</Alert>
									)}
								</Grid>
							</Grid>
						</OperationWrapper>
					</Grid>
				</Grid>
			</ExpandSelector>
		);
	}
}

DfpAdsFloorManager.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	system: PropTypes.object.isRequired,
};

ExternalSystemEdit.registerCustomRenderer('DfpAdsFloorManager', (p) => (<DfpAdsFloorManager {...p} />));
